import React from "react"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const About = ({ data, location }) => {
  return (
    <Layout>
      <SEO title="Über Uns" description="" pathname={location.pathname} />
      <figure className="title-image image is-2by1 super-size-image">
        <Img
          fluid={data.cockpitAbout.image.value.childImageSharp.fluid}
          className="fit-image"
        />
      </figure>
      <section className="section">
        <div className="container">
          <h1 className="title has-text-centered is-1">
            {data.cockpitAbout.Titel.value}
          </h1>
          <div
            className="marked-content"
            dangerouslySetInnerHTML={{ __html: data.cockpitAbout.Inhalt.value }}
          ></div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query About {
    cockpitAbout {
      Inhalt {
        value
      }
      Titel {
        value
      }
      image {
        value {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default About
